import "./App.css";
import { Login } from "./views/LoginPage/Login";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Dashboard } from "./views/Dashboard/Dashboard";
import { Charts } from "./views/Dashboard/Charts/Charts";
import { Moptro } from "./views/Moptro/Moptro";
import { ReportBody } from "./views/Reports/ReportBody";
import Layout from "./Layout";
import Signup from "./Signup";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes/ProtectedRoute";

//PI_2025_03
//Backend Repo Function-Connect-W2HConnect-com - PI_2025_03 Latest only
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/app/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />

          {/* Protect these routes */}
          <Route path="/app/Dashboard" element={<ProtectedRoute element={<Dashboard />} />} />

          <Route path="/app/Charts" element={<ProtectedRoute element={<Charts />} />} />
          <Route path="/app/Report" element={<ProtectedRoute element={<ReportBody />} />} />
          <Route path="/app/moptro" element={<ProtectedRoute element={<Moptro />} />} />

          {/* Catch all other routes and redirect */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
