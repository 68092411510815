import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie'
import "./loginBody.css";
import axios from "axios";
import { Header } from "./Header";
import { Products } from "./Products";
import { LoginFooter } from "./loginFooter/loginFooter";
import { useNavigate } from "react-router-dom";
import { setID, setName, setCustomer, setVerified, setUserType } from "../../redux/slice/userSlice";
import { setAuth } from "../../redux/slice/authSlice";
import Loader from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import analticsLogo from "../../assets/AnalyticsLogo.svg";
// import visiblePassword from "../../assets/PasswordVisible.svg";
// import invisiblePassword from "../../assets/PasswordInvisible.svg";
import { toast ,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';  // Import for toast notifications
import VerifiedAnimation from '../Verify/VerifiedAnimation';

export const LoginBody = () => {
  // localStorage.clear();
  // sessionStorage.clear();
  const [loading, setLoading] = useState(false);
  // const [otp, setOtp] = useState(Array(6).fill(""));
  const [otp, setOtp] = useState("");

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [email, setEmail] = useState(''); 
  // const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [emailExists, setEmailExists] = useState(true); // This will track if the email exists
  const [emailError, setEmailError] = useState(""); // This will store the error message
  const [animationDisplay,setAnimationDisplay]=useState(false);
  // New states for OTP timer and resend button functionality
  const [timer, setTimer] = useState(60);  // OTP timer
  const [isResendDisabled, setIsResendDisabled] = useState(true);  // Disable resend OTP initially
  const [isLoginView, setIsLoginView] = useState(true);  // Toggle login and OTP view
  const [errorMessage , setErrorMessage] = useState("");
  const [resetTimer, setResetTimer] = useState(false);  // New state flag to reset timer
  const userId=useSelector((state)=>state.user.id)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    
    const config = {
      css_url: "/app/embedded_signin_providers_only.css",
      signin_providers_only: true,
      forgot_password_css_url: "/app/fpwd.css",
    };
    const call = window.catalyst.auth.signIn("login", config);
    window.catalyst.auth.signIn("login", config);
    // setLoading(false);
  }, []);
  
  
  useEffect(() => {
    let interval;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsResendDisabled(false); // Enable Resend OTP button after 30 seconds
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const setHide=()=>{
    setErrorMessage("");
    setIsResendDisabled(true);
  }
  useEffect(() => {
    if (resetTimer) {
      setTimer(0);  // Reset the timer state
      setIsResendDisabled(false);  // Enable resend button
      setResetTimer(false);  // Reset the flag
    }
  }, [resetTimer]);
  
 
  const handleSubmit = async (e) => {
    setLoader(true);
    
    e.preventDefault();
    var body = { loginId: email, otp:otp }; 

    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:process.env.REACT_APP_API_KEY_DEV,
    };
    let url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "Signin"
        : process.env.REACT_APP_API_BASE_URL_PROD + "Signin";

    try {
      const response = await axios.post(url, body, {  headers, withCredentials: true }); 
      if (response.status === 200 && response.data.message === "Logging In Successful" ) {
        
          setHide();
          setAnimationDisplay(true);
          setResetTimer(0);
          dispatch(setVerified(true))
          dispatch(setID(response.data.contact_number))
          dispatch(setUserType(response.data.type))
          dispatch(setName(response.data.name))
          setTimeout(()=>{navigate("/app/Dashboard");},2000);
          localStorage.setItem("authenticated", true);
          setLoader(false);
      
      } 
      else if(response.data.status === 200)
      {
        setErrorMessage(response.data.message)
        setLoader(false);
      }
    } 
    catch (error) {
      // Check if it's an AxiosError
      if (error.response) {
        // You can access the error response from error.response
        if (error.response.status === 401) {
          setErrorMessage("No vehicle assigned.");
        } else if(error.response.status === 410){
          setErrorMessage(error.response.data.message);
        }else if(error.response.status === 403){
          setErrorMessage(error.response.data.message)
        }
      } else {
        setErrorMessage("Network or server error. Please try again later.");
      }
      setLoader(false);
    }
  };
  const handleSendOtp = async (e) => {
    
    setLoader(true);
    e.preventDefault();

    if (!email) {
      setEmailExists(false); // Set emailExists to false if no email is entered
      setEmailError("Please enter a valid email address.");
      // toast.error("Please enter a valid email address.");
      return;
    }

    const body = { loginId: email };

    const headers = {
      "Content-Type": "application/json",
      ZCFKEY:process.env.REACT_APP_API_KEY_DEV
    };

    const url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "generateOtp"
        : process.env.REACT_APP_API_BASE_URL_PROD + "generateOtp";

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.status === 200 && response.data.message === "OTP sent successfully") {
        setIsLoginView(false);
        setTimer(60);
        setIsResendDisabled(true);
        setIsOtpSent(true);
        toast.success("OTP sent successfully to your email.");
      } else if(response.data.status === 429){
        toast.error(response.data.message);
        
      }
    } catch (error) {
      if (error?.response) {
        const { status } = error.response;

        if (status === 401) {
            toast.error("Incorrect or wrong email!");
            setEmailExists(false);
        } else if (status === 403 ) {
            toast.error(error.response.data.message);
            setEmailExists(false);
        }else if(status === 429){
          toast.error(error.response.data.message);
        }

        // Reset email state after 3 seconds
        setTimeout(() => setEmailExists(true), 3000);
    } else {
        toast.error("Something went wrong. Please try again!");
        console.error("Unexpected error:", error);
    }
    } finally {
      setLoader(false);
    }
  };

  const handleResendOtp = (e) => {
    setErrorMessage("");

    if (timer === 0) {
      handleSendOtp(e);
    }
  };

  return (
    <div className="main-body">
      <Header />
      <ToastContainer/>
      {loader && <Loader />}
      <div className="login-window-container">
        <div className="login-window">
          <div className="logo-container">
            <img src={analticsLogo} className="analytics-logo" alt="Analytics Logo" />
          </div>

          <div className="verticle-line"></div>

          <div className="signup-container">
            <form className="signup" onSubmit={isOtpSent ? handleSubmit : handleSendOtp}>
              <div className="signup-title">{isOtpSent ? "Enter OTP" : "Sign In"}</div>
              {/* {isOtpSent && timer > 0 ? ( */}

              {!isOtpSent ? (
                <input
                type="text"
                name="username"
                value={email}
                placeholder={emailError || "Enter Email Id"} // Show the error message in the placeholder
                onChange={(e) => setEmail(e.target.value)}
                className={`signup-user ${!emailExists ? "error" : ""}`} // Apply the error class if email doesn't exist
                autoComplete="off"
                required={true}
              />
              
              ) : (<><div id="timer">
                <p>OTP is valid for {timer} Seconds only</p>
              </div>
                <div className="otp-input-container">
                  {/* {otp.map((digit, index) => (
                    <input
                      // key={index}
                      // id={`otp-input-${index}`}
                      type="text"
                      // maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(e, index)}
                      className="otp-input-box"
                      required
                    />)
                    )} */}


                <input
              className="login-input"
              type="text"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              required={true}
            />
                </div></>
              )}

              {isOtpSent && timer > 0 ? (
                   <div className="resend-container" >
                  <p
                    className="error-message"
                    style={{ visibility: !isResendDisabled && animationDisplay ? "hidden" : "visible" }}
                  >
                    {errorMessage}
                  </p></div> ) : (
                    <div className="resend-container">
                    <div>
                    <p
                      className="error-message"
                      style={{ visibility: isResendDisabled && !animationDisplay? "hidden" : "visible" }}
                    >
                      {errorMessage}
                    </p>
                    </div>
                    <div>
                    <button
                      id="resend-otp-button"
                      type="button"
                      onClick={handleResendOtp}
                      disabled={isResendDisabled}
                      style={{ visibility: isResendDisabled && !isOtpSent && !animationDisplay ? "hidden" : "visible" }}
                    >
                      Resend OTP
                    </button>
                    </div>
                  </div>
                  
              )}
              
              {isOtpSent ? (
                animationDisplay ? (<div className="verifiedAnimation">
                  <VerifiedAnimation />
                </div>
                  
                ) : (
                  <button
                  className="send-otp-btn"
                  type="submit"
                  disabled={isOtpSent && !otp}
                >Validate</button>
                )
              ) : (
                <>
                
                <div> <button
                className="send-otp-btn"
                type="submit"
                disabled={isOtpSent && !otp}
              >Send OTP</button></div>
              <div>

              </div>
               </>
              )}
            
            
              {/* {isOtpSent ? (
                animationDisplay ? (<div className="verifiedAnimation">
                  <VerifiedAnimation />
                </div>
                  
                ) : (
                  <button
                  className="send-otp-btn"
                  type="submit"
                  disabled={isOtpSent && otp}
                >Validate</button>
                )
              ) : (
                <>
                
                <div> <div className="zoho-login-container">
              <button
                className="send-otp-btn"
                type="submit"
                disabled={isOtpSent && otp}
              >Send OTP</button>
              <div className="signup-options">
                or
                </div>
              <div id="login"></div>
            </div></div>
              <div>

              </div>
               </>
              )} */}
      
            </form>

            <div className="signup-footer">
              <p>Privacy</p>
              <p>Terms & Conditions</p>
              <p>Help</p>
            </div>
          </div>
        </div>
      </div>
      <Products />
      <LoginFooter />
    </div>
  );
};
