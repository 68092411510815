import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import active from "../assets/Active.svg";
import { useNavigate } from "react-router-dom";
import {
  selectActiveItem,
  setActiveItem,
  setToggleMenu,
  setSelectedNavMenu,
  selectSelectedNavMenu,
} from "../redux/slice/sideNavbarSlice";
import { setID, setVerified } from "../redux/slice/userSlice";
import { setAuth } from "../redux/slice/authSlice";
import fetchApiData from "../service/appUtils";
const DropdownMenu = ({
  title,
  items,
  img,
  imgActive,
  defaultSelected,
  onMenuItemClick,
  toggle,
  className,
  user
}) => {
  const [isOpen, setIsOpen] = useState(onMenuItemClick ? false : true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeItem = useSelector(selectActiveItem);
  const toggleMenu = useSelector((state) => state.nav.toggleMenu);
  const catalystLogout=window.catalyst.auth;
  const username = useSelector((state) => state.user.name);

  useEffect(() => {
    // Update isOpen based on the toggle prop
    setIsOpen(toggle ? true : false);
  }, [toggle]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    //dispatch(setSelectedNavMenu("Dashboard"))
  };

  const handleClick = async (index, event, item) => {

    // event.preventDefault();
    // dispatch(setActiveItem(index));
    // dispatch(setToggleMenu(true));
    // if (onMenuItemClick && index === 1) {
    //   onMenuItemClick(index);
    // }    
    // //Logout                                               
    //   localStorage.clear();
    //   navigate("/app")  
    // setLoader(true); // Start loading state
    const getApiKey = () => {
      const env = process.env.REACT_APP_ENV === "DEVELOPMENT" ? "DEV" : "PROD";
      return process.env[`REACT_APP_API_KEY_${env}`];
    };
    const key=getApiKey();

    const headers = {
      "Content-Type": "application/json",
      ZCFKEY: key,
    };
  
    const url =
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_BASE_URL_DEV + "logout"
        : process.env.REACT_APP_API_BASE_URL_PROD + "logout";

    try {
      const response = await axios.post(url, {}, { headers, withCredentials: true, credentials: 'same-origin'});
      
      
      if (response.status === 200) {
        dispatch(setSelectedNavMenu("Dashboard"));
        dispatch(setActiveItem("Dashboard"));
        dispatch(setAuth(false));
        dispatch(setID(null));
        dispatch(setVerified(false))
        localStorage.clear();
        navigate("/login");
        // toast.success("Logged out successfully.");
      } else if(response.status === 400) {
        console.error("Relogin try again.");
        navigate("/");
      }
      else{
        console.error("Relogin try again.");
        navigate("/");
      }
    } catch (error) {
      navigate("/login");
    } finally {
      dispatch(setVerified(false))
      // setLoader(false); // Stop loading state
    }
  };
  return (
      <button className={className} onClick={toggleDropdown}>
      <div>{user}</div>
    <div className="dropdown">
        <img
          src={isOpen ? imgActive : img}
          alt="dashboard logo"
          className={toggleMenu ? "icon" : "original-icon"}
        />
        {title}
      
      {isOpen && (
        <div className="dropdown-menu">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={(event) => handleClick(index, event, item)}
              className={"active"}
            >
              {/* <img
                src={active}
                className={index === activeItem ? "" : "active"}
              /> */}

              {item}
            </div>
          ))}
        </div>
      )}
    </div>
    </button>
  );
};

export default DropdownMenu;
