import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {thunk} from "redux-thunk";

import userSlice from "./slice/userSlice";
import authSlice from "./slice/authSlice";
import graphSlice from "./slice/graphSlice";
import shiftSlice from "./slice/shiftSlice";
import moptroSlice from "./slice/moptroSlice";
import dashboardSlice from "./slice/dashboardSlice";
import sideNavbarSlice from "./slice/sideNavbarSlice";
import filterSlice from "./slice/filterSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedUserReducer = persistReducer(persistConfig, userSlice);
const persistedAuthReducer = persistReducer(persistConfig, authSlice);
const persistedSidenavbar =persistReducer(persistConfig,sideNavbarSlice);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    auth: persistedAuthReducer,
    graph: graphSlice,
    shift: shiftSlice,
    dashboard: dashboardSlice,
    moptro: moptroSlice,
    nav:persistedSidenavbar,
    filter:filterSlice
  },
  devTools: true,
  devTools: process.env.REACT_APP_ENV !== "PRODUCTION",
  middleware: [thunk],
});

export const persistor = persistStore(store);
