import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  name: " ",
  customer:null,
  verified:false,
  userType:'Admin'
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setCustomer:(state,action)=>{
      state.customer=action.payload;
    },
    setVerified: (state, action) => {
      state.verified = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
  },
});

export const { setID, setName,setCustomer,setVerified,setUserType } = userSlice.actions;
export default userSlice.reducer;
