import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  option: "Last 30 Days",
  date: dayjs(),
  shift: "both",
  startDate: null,
  endDate: null,
  filterData:null,
  uptime:null,
  liveData:null
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashOption: (state, action) => {
      state.option = action.payload;
    },
    setDashDate: (state, option) => {
      state.date = option.payload;
    },
    setDashShift: (state, action) => {
      state.shift = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    setUptime: (state, action) => {
      state.uptime = action.payload;
    },
    setLiveData: (state, action) => {
      state.liveData = action.payload;
    },

  },
});

export default dashboardSlice.reducer;
export const {
  setDashOption,
  setDashDate,
  setDashShift,
  setStartDate,
  setEndDate,
  setFilterData,
  setUptime,
  setLiveData
  
} = dashboardSlice.actions;
