import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";

export const UserDropdown = ({ selectedOption = () => {}, filterUser, defaultSelectedOption }) => {
  const allUsers = useSelector((state) => state.user.customer);
  const loginID = useSelector((state) => state.user.id);
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [selectedLabel, setSelectedLabel] = useState("");

  const defaultUser = allUsers.find((user) => user.locationId === loginID);

  useEffect(() => {
    const savedValue = localStorage.getItem("selectedCustomer");

    if (savedValue) {
      const parsedValue = JSON.parse(savedValue);
      setValue(parsedValue.value);
      setSelectedLabel(parsedValue.label);
      selectedOption(parsedValue.value);
    } else if (defaultUser) {
      setValue(defaultSelectedOption);
      setSelectedLabel(`${defaultUser.customer_name} - ${defaultUser.location_name}`);
      selectedOption(defaultSelectedOption);
    }
  }, []);

  const filteredUsers = allUsers?.filter((user) => user.locationId !== value);
  let options;
  if (filterUser) {
    options = filteredUsers?.map((user) => ({
      value: user.locationId,
      label: `${user.customer_name} - ${user.location_name}`,
    }));
  }

  const handleChange = (selectedValue) => {
    if (!selectedValue) {
      // Reset to default
      setValue(loginID);
      if (defaultUser) {
        setSelectedLabel(`${defaultUser.customer_name} - ${defaultUser.location_name}`);
      }
      selectedOption();
      localStorage.removeItem("selectedCustomer");
    } else {
      const selectedOptionObj = options.find((option) => option.value === selectedValue);
      if (selectedOptionObj) {
        setValue(selectedOptionObj.value);
        setSelectedLabel(selectedOptionObj.label);
        localStorage.setItem("selectedCustomer", JSON.stringify(selectedOptionObj));
        selectedOption(selectedOptionObj.value);
      }
    }
  };

  let selectStyles = {
    minWidth: 120,
    maxWidth: 300,
  };

  if (window.matchMedia("(width: 1280px)").matches) {
    selectStyles.maxWidth = 245;
  }

  return (
    <Select
      style={selectStyles}
      options={options}
      popupMatchSelectWidth={false}
      allowClear
      value={selectedLabel || undefined} // Ensure it's undefined when there's no label
      onChange={handleChange}
      placeholder="Customers"
    />
  );
};

export default UserDropdown;
