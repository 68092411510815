import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./reports.css";

import SelectAntD from "../Dashboard/Chart/SelectAntD";
import RangeDatePicker from "./RangeDatePicker";
import DateTimePicker from "../Dashboard/Chart/TimeRender";
import Loader from "../Loader/Loader";
import fetchApiData from "../../service/appUtils";
import dayjs from "dayjs";

export const Reports = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth.isAuth);

  // useEffect(() => {
  //   if (!auth) navigate("/app");
  // }, []);

  const defaultDay1 = dayjs().subtract(30, 'days');
  const defaultDay2 = dayjs()
  const orgName = useSelector((state) => state.user.name);
  const orgID = useSelector((state) => state.user.id);

  const [VINOpen, setVINOpen] = useState(false);
  const [isValid, setIsvalid] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Daywise");
  const [Data, setData] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("23:59");
  const [date, setDate] = useState("");
  const [prevDate, setPrevDate] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSelectVin = (event) => {
    setSelectedOption(event);
    setSelectedDates([])
  };
  const handleIsOpen = (event) => {
    setVINOpen(event);
  };
  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };
  const handleStartTime = (time) => {
    setStartTime(time);
  };
  const hadndleDate = (date) => {
    setDate(date);
    setStartTime("00:00");
    setEndTime("23:59");
  };
  const handleEndTime = (time) => {
    setEndTime(time);
  };
  function formatDateToISO(inputDate) {
    // Split the input date string into day, month, and year parts
    const [day, month, year] = inputDate.split("-");

    // Use the parts to create the ISO format (YYYY-MM-DD) string
    const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;

    return isoDate;
  }
  function compareTimes(time1, time2) {
    var [hour1, minute1] = time1.split(":").map(Number);
    var [hour2, minute2] = time2.split(":").map(Number);

    if (hour1 > hour2) {
      return 1;
    } else if (hour1 < hour2) {
      return -1;
    } else {
      if (minute1 > minute2) {
        return 1;
      } else if (minute1 < minute2) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  const defaultParam = {
    id: orgID,
    starttime: dayjs().format("YYYY-MM-DD") + " 00:00:00",
    endtime: dayjs().format("YYYY-MM-DD") + " 23:59:59",
    reportType: "Daywise",
  };

  const fetchData = async (param) => {
    const endpoint = `/record?id=${param.id}&starttime=${param.starttime}&endtime=${param.endtime}&reportType=${param.reportType}`;
    setLoader(true);
    try {
      const response = await fetchApiData(endpoint);
      if (response.status === "200") {
        setData(response.data);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleSubmit = async () => {
    if (selectedOption === null) {
      alert("Select Report Type ");
    } else {
      let sTime;
      let eTime;
      let dayDate;
      let valid = false;
      if (selectedOption === "Daywise") {
        if (startTime.startsWith("24:")) {
        } else {
          sTime = startTime;
        }
        eTime = endTime;

        const time = date.$d;
        if (time) {
          const formattedDate = time
            .toLocaleDateString("en-GB", {
              year: "numeric",
              day: "2-digit",
              month: "2-digit",
            })
            .replace(/\//g, "-");

          const ISOdate = formatDateToISO(formattedDate);
          dayDate = ISOdate;
          if (sTime !== "" && eTime !== "" && dayDate !== "") {
            sTime = ISOdate + " " + sTime + ":00";
            eTime = ISOdate + " " + eTime + ":00";
            valid = true;
          }
        }
      }

      if (selectedOption === "Date Range") {
        if (selectedDates !== []) {
          sTime = selectedDates[0];
          eTime = selectedDates[1];
          valid = true;
        } else valid = false;
      }
      if (sTime === "" || eTime === "") {
        alert("Enter Date");
        valid = false;
      }
      if (selectedOption === "Daywise") {
        const comp = compareTimes(sTime, eTime);
        if (comp === 1) {
          alert("Start Date Can't be bigger than End date");
          valid = false;
        }
        if (date === "") {
          alert("Choose another date!!");
          valid = false;
        }
      }
      if (sTime === undefined || eTime === undefined) {
        alert("choose Start and End date");
        valid = false;
      }
      if (valid) {
        // setData([]);
        setPrevDate(date);
        setIsvalid(true);
        setLoader(true);

        const param = {
          id: orgID,
          starttime: sTime,
          endtime: eTime,
          reportType: selectedOption,
        };
        //checks  demo username and provides demo report  data
        if (orgName === "demo") {
        } else {
          fetchData(param);
        }
      }
    }
  };

  useEffect(() => {
    fetchData(defaultParam);
  }, []);
  return (
    <div className="main_body">
      <div id="load" style={{ display: loader ? "flex" : "none" }}>
        <span className="loader"></span>
      </div>
      {/* <div className="mop_heading">MOPTro Report </div> */}
      <div className="parameter">
        <div className="params">
          <div className="repo">
            <div className="select_heading">Report Type:</div>
            <div
              className={
                VINOpen ? "idx_open-vehicle-number" : "idx_vehicle-number"
              }
            >
              <SelectAntD
                selectedOption={handleSelectVin}
                name={selectedOption}
                report={true}
                dateRange={true}
              />
            </div>
          </div>
          {selectedOption === "Date Range" && (
            <RangeDatePicker
              onDateChange={handleDateChange}
              warn={false}
              selectedDates={selectedDates}
              page={"Reports"}
            />
          )}
          {selectedOption === "Daywise" && (
            <DateTimePicker
              onStartTimeChange={handleStartTime}
              onEndTimeChange={handleEndTime}
              onDate={hadndleDate}
              show={true}
            // date={defaultDay2}
            />
          )}
          <button id="submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      {renderReport(
        Data,
        selectedOption,
        prevDate,
        selectedDates,
        startTime,
        endTime
      )}
    </div>
  );
};
const renderReport = (
  Data = [],
  option,
  date,
  selectedDate,
  startTime,
  endTime
) => {
  let fileName;
  if (option === "Daywise") {
    fileName = "MOPTro_Report_" + date + "_" + startTime + "_" + endTime;
  } else {
    fileName = "MOPTro_Report_" + selectedDate[0] + "_" + selectedDate[1];
  }

  async function convertArrayOfObjectsToCSV(data) {
    const separator = ",";
    let temp = [
      "createddate",
      "cvin",
      "usageInMin",
      "iDistanceTravelled",
      "fSpeedKmphAvgAbs",
      "iChargeCycles",
      "iStartStopCyclesByAmps",
      "iOLcountPeakAmps",
    ];
    let params = [
      "Date",
      "Vehicle Number",
      "Vehicle Usage (mins)",
      "Distance(kms)",
      "Average Speed(km/h)",
      "Charge Cycles",
      "Start Stop Cycles",
      "Overload Count",
    ];
    let keys = Object.keys(data[0]);
    const csvContent =
      params.join(separator) +
      "\n" +
      data
        .map((item) => {
          return temp
            .map((key) => {
              if (key === "iDistanceTravelled") {
                const dis = (item[key] / 1000).toFixed(2);
                return dis;
              }
              if (key === "fSpeedKmphAvgAbs") {
                return Number(item.fSpeedKmphAvgAbs).toFixed(2);
              }
              if (key === "usageInMin") {
                return Number(item.usageInMin).toFixed(2);
              } else return item[key];
            })
            .join(separator);
        })
        .join("\n");
    return csvContent;
  }

  const op = option;
  const handleDownload = () => {
    convertArrayOfObjectsToCSV(Data)
      .then((csv) => {
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const downloadLink = document.createElement("a");
        downloadLink.id = "temp_id";
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = fileName + ".csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div id="main_report">
      <div className="download_container">
        <div className="Mop_title">MOPTro Report :</div>
        <div id="download" onClick={handleDownload} download="data2.csv">
          Save Report
        </div>
      </div>

      <div className="form">
        <div id="fixed_heading" className="report">
          <div className="heading">Date</div>
          <div className="heading">Vehicle Number</div>
          <div className="heading">Vehicle Usage (mins)</div>
          <div className="heading">Distance (kms)</div>
          <div className="heading">Average Speed (km/h)</div>
          <div className="heading">Charge Cycles</div>
          <div className="heading">Start Stop Cycles</div>
          <div className="heading">Overload Count</div>
          <div className="grid-container"></div>
        </div>
        <div className="main_form">
          {Data.map((item, index) => (
            <div key={index} className="report">
              <div>{item.createddate}</div>
              <div>{item.cvin}</div>
              <div>{Number(item.usageInMin).toFixed(2)}</div>
              <div>{(item.iDistanceTravelled / 1000).toFixed(2)}</div>
              <div>{Number(item.fSpeedKmphAvgAbs).toFixed(2)}</div>
              <div>{item.iChargeCycles}</div>
              <div>{item.iStartStopCyclesByAmps}</div>
              <div>{item.iOLcountPeakAmps}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
