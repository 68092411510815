import React, { useEffect, useState } from "react";
import UserProfile from "./UserProfile";
import LoginPage from "./LoginPage.js";
import { Dashboard } from "../src/views/Dashboard/Dashboard.js";
import { Login } from "./views/LoginPage/Login.js";
import { useNavigate } from "react-router-dom";
import fetchApiData, { sendRequest } from "./service/appUtils.js";
import { useSelector } from "react-redux";
function Layout() {
  const [isFetching, setIsFetching] = useState(true);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    mailid: "",
    timeZone: "",
    createdTime: "",
  });
  const authenticated=useSelector((state)=>state.user.verified);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await window.catalyst.auth.isUserAuthenticated();
        if (result && result.content) {
          const { first_name, last_name, email_id, time_zone, created_time } =
            result.content;
          setUserDetails({
            firstName: first_name,
            lastName: last_name,
            mailid: email_id,
            timeZone: time_zone,
            createdTime: created_time,
          });
          setIsUserAuthenticated(true);
        }
      } catch (err) {
        // Handle error
        console.error("Error fetching user details:", err);
      } finally {
        setIsFetching(false);
      }
    };

    // fetchData();
    if (isUserAuthenticated) {
      // fetchUserDetails();
      // adminLogin();
    }
  }, []);
  return (
    <>
      { authenticated ? (
       <Dashboard/>
      ) : (
        <Login />
      )}
    </>
  );
}

export default Layout;
