import { Navigate,Outlet, renderMatches } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Login } from "../views/LoginPage/Login";

const ProtectedRoute = ({element}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    // useEffect(()=>{
    //     const checkSession = async () => {
    //         try {
    //           const headers = {
    //             "Content-Type": "application/json",
    //             ZCFKEY:
    //               process.env.REACT_APP_ENV === "DEVELOPMENT"
    //                 ? process.env.REACT_APP_API_KEY_DEV
    //                 : process.env.REACT_APP_API_KEY_PROD,
    //           };
          
    //           let url =
    //             process.env.REACT_APP_ENV === "DEVELOPMENT"
    //               ? process.env.REACT_APP_API_BASE_URL_DEV + "verify-session"
    //               : process.env.REACT_APP_API_BASE_URL_PROD + "verify-session";
          
    //           const response = await fetch(url, {
    //             method: "GET",
    //             headers: headers,
    //             credentials: "include", 
    //             withCredentials:true,
    //           });
          
    //           if (response.ok) {
    //             setIsAuthenticated(true);
    //           } else {
    //             setIsAuthenticated(false);
    //           }
          
    //           // const data = await response.json();
    //         } catch (error) {
    //           console.error("Session check failed:", error);
    //           setIsAuthenticated(false);
    //         }
    //       };
    //       checkSession();
    // },[])
    const isAuth = useSelector((state) => state.user.verified); // Check auth state

    return isAuth ? element : <Navigate to="/login" replace />; // Redirect if not authenticated 
};

export default ProtectedRoute;
